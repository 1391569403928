import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import axios from 'axios';

// import './index.scss';

const Order = () => {
  const formRef = React.useRef(null);
  const [cartItem, setCartItem] = React.useState(null);
  const [validated, setValidated] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const items = [
    {
      title: 'Three Rose Bouquet',
      price: 15.00,
      description: 'Ready for pickup the day of the show!'
    },
    {
      title: 'Single Rose Bouquet',
      price: 5.00,
      description: 'Ready for pickup the day of the show!'
    },
    {
      title: 'Candygram',
      price: 5.00,
      description: 'Delivered to your dancer the day of the show!'
    },
  ];

  const handleChange = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  const handleSubmit = async (sourceId) => {
    const { formDancerName, formDancerLevel, formPurchaserName, formEmail, formPhone } = formRef.current;
    try {
      await axios.post('/api/orders', {
        formDancerName: formDancerName.value,
        formPurchaserName: formPurchaserName.value,
        formDancerLevel: formDancerLevel.value,
        formEmail: formEmail.value,
        formPhone: formPhone.value,
        cartItem,
        price: ((cartItem.price + .30) * 1.029).toFixed(2),
        sourceId,
        eventId: 1,
      });
      setIsCompleted(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container className="text-center d-block" style={{ maxWidth: 720 }}>
      {isCompleted ? (
        <h3 className="text-success mt-5">Thank you for your order. Roses will be available for pickup and candygrams will be delivered the day of the event.</h3> 
      ) : (
        <>
          <h3 className="mt-5">Ordering is Open for the 2024 AAD Nutcracker Shows!</h3>
          <div>Please complete your order below by selecting and paying for the item you wish to order.</div>
          {cartItem ? (
            <div className="d-block text-center">
              <div className="p-3 mt-4" style={{ border: '1px solid #282c34' }}>
                <strong>All fields required.</strong>
                <Form noValidate validated={validated} onChange={handleChange} ref={formRef}>
                <Form.Group className="mb-3" controlId="formPurchaserName">
                    <Form.Label>Purchaser Name:</Form.Label>
                    <Form.Control required type="text" placeholder="Enter Purchaser Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Purchaser Email:</Form.Label>
                    <Form.Control required type="email" placeholder="Enter Purchaser Email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Label>Purchaser Phone:</Form.Label>
                    <Form.Control required type="phone" placeholder="Enter Purchaser Phone" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDancerName">
                    <Form.Label>Full Name of Dancer:</Form.Label>
                    <Form.Control required type="text" placeholder="Enter Dancer Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDancerLevel">
                    <Form.Label>Dancer Ballet Level and/or Teacher:</Form.Label>
                    <Form.Control required type="text" placeholder="Enter Dancer Ballet Level and/or Teacher" />
                  </Form.Group>
                  <div className="my-3"><strong>Total Price (including fees): $ {((cartItem.price + .30) * 1.029).toFixed(2)}</strong></div>
                  <PaymentForm
                    applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
                    cardTokenizeResponseReceived={async (token)  => {
                      if (validated) {
                        handleSubmit(token.token);
                      }
                    }}
                    locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                  >
                    <CreditCard />
                  </PaymentForm>
                </Form>
                <Button variant="danger" className="w-100 mt-1" style={{ height: 48 }} onClick={() => setCartItem(null)}>Cancel Payment</Button>
              </div>
            </div>
        ) : (
          <div className="mt-4 text-center">
            {items.map((item, idx) => (
              <div className="pt-3" style={{ border: '1px solid #282c34' }} key={`item-${idx}`}>
                <h3>{item.title}</h3>
                <h5>$ {item.price.toFixed(2)}</h5>
                <p>{item.description}</p>
                <Button className="btn-dark mb-4" style={{ backgroundColor: '#282c34' }} onClick={() =>  setCartItem(items[idx])}>Buy</Button>
              </div>
            ))}
          </div>
        )}
        </>
      )}
    </Container>
  )
}

export default Order;